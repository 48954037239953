import React from 'react';
import {Link} from 'gatsby';
import Layout from "../components/layout";
import {Helmet} from "react-helmet";

const ProductsPage = ({ data }) => (
  <Layout>
   
    <div className="flex flex-col items-center pt-32">
    <Helmet>
        <title>Cymetrix Software | Calendar Product </title>
        <meta name="description" content="Salesforce consultant Mumbai-Cymetrix has developed a salesforce product app i.e CalendarInSight that gives a consolidated view of all meetings in 1 calendar."/>
        <meta name="keywords" content="cymetrix,salesforce crm software implementation partners india,salesforce cpq partner india,salesforce.com crm partners india,salesforce partners in india,salesforce consultants india,salesforce marketing cloud partners india,salesforce partners in mumbai,salesforce partners in USA,salesforce implementation partners usa,SALESFORCE PARTNERS INDIA,salesforce consultants india, Cymetrix, cymetrixsoft, Cymetrixsoftware"/>
        <link rel="canonical" href="https://www.cymetrixsoft.com/products/" />
      </Helmet>
      <main className="w-full flex flex-col items-center">
        <div className="max-w-5xl mx-4">
            <div className="flex flex-col relative">
                <img class="w-64 items-center mx-auto mb-10" src={require('../images/product/CalApp.png')} title="Cymetrix | CalendarInsight Salesforce App" alt="CalendarInsight Salesforce App by Cymetrix Software"/>
            </div>
        </div>
        <div className="product-background w-full flex flex-col items-center" title="Background image of Products" alt="Products Background  | Cymetrix Software">
            <div className="max-w-6xl mx-4 my-16">
                <h1 className="text-white text-4xl text-center sma-xl font-thin">TRACK PERFORMANCE AND RESULTS OF YOUR SALES TEAM IN SALESFORCE CRM</h1>
                <p className="text-white text-2xl md:text-3xl sm:text-2xl text-center sma-xl font-thin mt-6">Cymetrix CalendarInsight Salesforce App gives you a consolidated view of all meeting across sales teams in one Calendar
</p>
                <img className="mt-20 mx-auto image-product" src={require('./../images/product/CalendarInsight.jpg')} alt="meeting-insight"/>

                <div class="flex flex-col sm:flex-row sm:-mx-2 mt-10">
                    <div class="mt-4 sm:w-1/3">
                        <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                            <div className="text-white text-center text-lg">GET ACCOUNT PENETRATION METRICS FROM ABM CAMPAIGNS</div>
                            <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                            <div className="mt-2 text-base text-gray-300 text-center">Give insights to your Sales Ops about how the team is penetrating accounts. Access the visual ‘heat map’ of penetration metrics and determine ABM engagement by persona, region, industry, etc</div>
                        </div>
                    </div>
                    
                    <div class="mt-4 sm:w-1/3">
                        <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                            <div className="text-white text-center text-lg">EXTRACT ACTIONABLE MEETING INSIGHTS FOR FIELD MANAGEMENT</div>
                            <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                            <div className="mt-2 text-base text-gray-300 text-center">Your sales team wants to extract quick actionable insights from meeting data in Salesforce. But in their native app, they waste time pulling together meeting data from activities that are separate from leads, opportunities, and contacts. Cymetrix CalendarInSight Software application automatically syncs calendars of sales teams to give a consolidated view to monitor meetings.</div>
                        </div>
                    </div>

                    <div class="mt-4 sm:w-1/3">
                        <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                            <div className="text-white text-center text-lg">EVALUATE HOW WELL THE SDRS ARE ACHIEVING THEIR MEETING GOALS</div>
                            <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                            <div class="mt-2 text-base text-gray-300 text-center">Your inside sales manager will find it easy to monitor strategic account penetration trends. Start maintaining a leaderboard of top performers and motivate SDRs to achieve meeting objectives.
</div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-center mt-10">
                <Link to="/contact-us" className="uppercase font-bold px-8 lg:px-10 py-3 rounded bg-gray-500 text-gray-100 hover:bg-hover focus:shadow-outline focus:outline-none transition duration-300">
                 Contact Cymetrix
                  </Link>
                    </div>
            </div>
        </div>
      </main>
    </div>
  </Layout>
);

export default ProductsPage;
